.modal-footer {

}

div[role="dialog"] {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 20px;
  color: #595959;
}

div[role="dialog"] h4 {
  margin: 0;
  font-family: "Source Sans Pro", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  line-height: 1.2;
}
